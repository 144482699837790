import { NavBar } from 'components/organisms';
import { Vacancy } from 'types/vacancy';

interface LayoutProps {
  children: React.ReactNode;
  vacancy?: Vacancy;
}

export const LayoutRecommendation = ({ children, vacancy }: LayoutProps) => (
  <main className="flex bg-gray-200 flex-col min-h-screen ">
    <NavBar vacancy={vacancy} isExternalPage />
    <div className="flex-grow gap-y-6 bg-[#f4f6f8]">{children}</div>
  </main>
);
